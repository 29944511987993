var Brockwells = (function () {
	"use strict";
	return {
		Initialise: function () {

			$( '.main-nav ul' ).parent().prepend( '<button class="menu-toggle"></button>' );

			$( document.body ).on( 'click', '.menu-toggle', function () {
				$( this ).parent().toggleClass( 'menu-open' );
			});

			$( '.navicon' ).on( 'click', function () {
				$( document.body ).toggleClass( 'main-nav-open' );
			});

			if ( $( '.gallery__slider' ).length > 0 ) {
				$( '.gallery__slider' ).slick({
					prevArrow: '<button style="display: inline-block;" type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Previous" role="button"></button>',
					nextArrow: '<button style="display: inline-block;" type="button" data-role="none" class="slick-next slick-arrow" aria-label="Previous" role="button"></button>',
					adaptiveHeight: true
				});
			}

			$( '.accordion h3' ).on( 'click', function () {
				$( '.accordion h3' ).removeClass( 'currently-open' );
				$( '.accordion__content' ).each( function () {
					if (  $( this ).css( 'display' ) === 'block' ) {
						$( this ).slideUp();
					}
				});
				if (  $( this ).next( '.accordion__content' ).css( 'display' ) === 'none' ) {
					$( this )
						.addClass( 'currently-open' )
						.next( '.accordion__content' )
							.slideDown();
				}
			});

			if ( $( '#map' ).length > 0 ) {
				var element = document.createElement( 'script' );
				element.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&sensor=false&callback=Brockwells.Map&key=AIzaSyDZ1qYG-mHaQ5E4nAszS69EWwHn4mh0SQ8';
				document.body.appendChild( element );
			}

		},

		Map: function () {

			var center = new google.maps.LatLng( $( '.map' ).data( 'lat' ), $( '.map' ).data( 'lng' ) ),
				mapOptions = {
					zoom: 17,
					center: center,
					minZoom: 9,
					maxZoom: 19,
					mapTypeControl: false
				},
				map = new google.maps.Map( document.getElementById( 'map' ), mapOptions ),
				marker = new google.maps.Marker(
					{
						position: center,
						map: map,
						visible: true,
						icon: {
							url: '/wp-content/themes/brockwells/assets/images/pin.png'
						}
					}
				),
				styles = [
					{
						featureType: "all",
						stylers: [
							{
								saturation: -100
							}
						]
					}
				];

			map.setOptions( { styles: styles } );
		}
	};
}());

$( Brockwells.Initialise );